import VueRouter from 'vue-router'
import { Auth } from 'aws-amplify'

// Views
const Dashboard = () => import('@/views/Dashboard')
const crptoConector = () => import('@/components/CryptoDashboard/crptoConector')
const RGBHome = () => import('@/components/RGBHome')
const Dashboard2 = () => import('@/components/Dashboard')
const CryptoDashboard = () => import('@/components/CryptoDashboard/CryptoDashboard')
const InfoView = () => import('@/components/CryptoDashboard/InfoView')
const cctx_test = () => import('@/components/cctx/cctx_test')
const Uniswap = () => import('@/components/labs/Uniswap')
const Multipropiedad = () => import('@/components/trading/Multipropiedad')
const Exchanges = () => import('@/components/exchanges/exchanges')
const Assets = () => import('@/components/exchanges/allcoinsbalance')

// custom
const Login = () => import('@/components/Login')
// Containers
const TheContainer = () => import('@/containers/TheContainer')


const trading_navbar = [
  { path: 'CryptoDashboard', name: 'CryptoDashboard', component: CryptoDashboard },
  { path: 'Multipropiedad', name: 'Multipropiedad', component: Multipropiedad },
  { path: 'InfoView', name: 'infoview', component: InfoView, props: true },
  { path: 'Exchanges', name: 'Exchanges', component: Exchanges },
  { path: 'Assets', name: 'Assets', component: Assets }
]


const crypto_gateway_navbar = [
  { path: 'crypto', name: 'Crypto', component: crptoConector },
]

const labs_navbar = [
  { path: 'API_test2', name: 'API_test2', component: RGBHome },
  { path: 'api_test', name: 'api_test', component: Dashboard2 },
  { path: 'Dashboard', name: 'Dashboard', component: Dashboard },
  { path: 'Uniswap', name: 'Uniswap', component: Uniswap },
]


const main_navbar = [
  { path: 'trading/', component: TheContainer,  children: trading_navbar , meta: { requiresAuth: true} , name: 'trading'},
  { path: 'crypto-gateway/', component: TheContainer ,  children: crypto_gateway_navbar , meta: { requiresAuth: true} , name: 'crypto-gateway'},
  { path: 'labs/', component: TheContainer,  children: labs_navbar , meta: { requiresAuth: true} , name: 'labs'}
]


const routes = [
  { path: '/', redirect: { name: 'CryptoDashboard' } },
  { path: '/auth', component: Login },
  { path: '/crew/', component:  { render (c) { return c ('router-view') }} ,children: main_navbar , meta: { requiresAuth: true} , name: 'crew'}
]


const router = new VueRouter({
  routes
})


router.beforeResolve((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    Auth.currentAuthenticatedUser().then(() => {
      next()
    }).catch(() => {
      next({
        path: '/auth'
      });
    });
  }
  next()
})

export default router