import Vue from 'vue'
import Vuex from 'vuex'
//import defaultPair from '@/assets/defaultpair.json'
import defaultPair from '@/assets/defaultposition.json'

Vue.use(Vuex)
const ccxt = require('ccxt')
function binance_orders_tictac(){
  state.timer_binance_getallOrders = setInterval( () => {
    if (state.timer_binance_getallOrders > 0) {
      state.timer_binance_getallOrders--
    }
  }, 1000 )
}

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  currencies: localStorage.getItem('vue-crypto-currencies-new')? JSON.parse(localStorage.getItem('vue-crypto-currencies-new')) : defaultPair,
  tickers: {},
  chartData: [],
  //  cctx
  exchanges: [...ccxt.exchanges],
  exchange: '',
  pairs: [],
  pair: '',
  trades: [],
  binance_api_key: localStorage.getItem('binance_api_key')? localStorage.getItem('binance_api_key') : '',
  binance_api_secret: localStorage.getItem('binance_api_secret')?localStorage.getItem('binance_api_secret') : '',
  bitforex_api_key: localStorage.getItem('bitforex_api_key')? localStorage.getItem('bitforex_api_key') : '',
  bitforex_api_secret: localStorage.getItem('bitforex_api_secret')?localStorage.getItem('bitforex_api_secret') : '',
  gateio_api_key: localStorage.getItem('gateio_api_key')? localStorage.getItem('gateio_api_key') : '',
  gateio_api_secret: localStorage.getItem('gateio_api_secret')?localStorage.getItem('gateio_api_secret') : '',
  bitmax_api_key: localStorage.getItem('bitmax_api_key')? localStorage.getItem('bitmax_api_key') : '',
  gateio_api_secret: localStorage.getItem('gateio_api_secret')?localStorage.getItem('gateio_api_secret') : '',
  kucoin_api_key: localStorage.getItem('kucoin_api_key')? localStorage.getItem('kucoin_api_key') : '',
  kucoin_api_secret: localStorage.getItem('kucoin_api_secret')?localStorage.getItem('kucoin_api_secret') : '',
  kucoin_api_password: localStorage.getItem('kucoin_api_password')?localStorage.getItem('kucoin_api_password') : '',
  crypto_assets: localStorage.getItem('crypto_assets')? JSON.parse(localStorage.getItem('crypto_assets') ): [],
  crypto_orders: localStorage.getItem('crypto_orders')? JSON.parse(localStorage.getItem('crypto_orders') ): [],
  timer_binance_getallOrders: 0,
  marketOptions: ['binance','uniswap','bitforex','bilaxy', 'gateio','bitmax','kucoin'],
  dman_call_Type: ['Premium Call','Premium Call+','Golden dot','Golden dot+','GEM', 'GEM+'],
}

const  getters =  {
  getSymbolById: state => (symbol) => {
    return state.currencies.find(s => s.symbol === symbol);
  },
  getTickerById: state => (symbol) => {
    return state.tickers[symbol]
  }
}
const  actions =  {
  async getMarketsPair({ commit, state }, exchange){
      try {
          const newEx = new ccxt[state.exchange]()
          const pairs = await newEx.loadMarkets();
          commit('getMarketsPair', pairs)
      } catch (error) {
          console.log(error)
      }
  },
  getExchange({ commit }, exchange) {
      commit('getExchange', exchange)
  },
  startTimer_binance_getallOrders({ commit }) {
    state.timer_binance_getallOrders = 700
    binance_orders_tictac()
  },
  getPair({ commit }, pair) {
      commit('getPair', pair)
  },
  async getTrades({ commit, state }) {
      try {
          const newEx = new ccxt[state.exchange]()
          const symbol = state.pair
          const limit = 20
          const since = undefined
          const trades= await newEx.fetchTrades(symbol, since, limit)
          commit('getTrades', trades)
      } catch (error) {
          console.log(error)
      }
  },
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  UPDATE_POSITION: (state, payload) => {
    const tick = state.tickers[payload.symbol]
    payload.pchg = tick ? (payload.price > tick.price? 1 : -1 ) : 1
    Vue.set(state.tickers, payload.symbol, payload)
  },
  UPDATE_TICKER: (state, payload) => {
    const tick = state.tickers[payload.symbol]
    payload.pchg = tick ? (payload.price > tick.price? 1 : -1 ) : 1
    Vue.set(state.tickers, payload.symbol, payload)
  },
  ADD_COIN_PAIR: (state, payload) => {
    if(!state.tickers[payload.symbol]) {
      state.currencies.push(payload);
      localStorage.setItem('vue-crypto-currencies-new', JSON.stringify(state.currencies))
    }
    Vue.set(state.tickers, payload.symbol, { pchg: 1 })

  },
  REMOVE_COIN_PAIR: (state, symbol) => {
    Vue.delete(state.tickers, symbol)
    state.currencies.splice(state.currencies.findIndex(s => s.symbol === symbol), 1);
    localStorage.setItem('vue-crypto-currencies-new', JSON.stringify(state.currencies))
  },
  getTrades(state, payload) {
      state.trades = payload
  },
  getMarketsPair(state, payload) {
      state.pairs = payload
  },
  getPair(state, payload) {
      state.pair = payload
  },
  getExchange(state, payload) {
      state.exchange = payload
  },
  clearCoins(state, ) {
    localStorage.setItem('vue-crypto-currencies-new', JSON.stringify([]))
  },
  // Trading apis
  setBinanceApikey(state, binance_api_key) {
    state.binance_api_key = binance_api_key
    localStorage.setItem('binance_api_key', binance_api_key)
  },
  setBinanceApiSecret(state, binance_api_secret) {
    state.binance_api_secret = binance_api_secret
    localStorage.setItem('binance_api_secret', binance_api_secret)
  },
  setBitforexApikey(state, bitforex_api_key) {
    state.bitforex_api_key = bitforex_api_key
    localStorage.setItem('bitforex_api_key', bitforex_api_key)
  },
  setBitforexApiSecret(state,bitforex_api_secret ) {
    state.bitforex_api_secret = bitforex_api_secret
    localStorage.setItem('bitforex_api_secret', bitforex_api_secret)
  },
  setGateioApiKey(state,gateio_api_key) {
    state.gateio_api_key = gateio_api_key
    localStorage.setItem('gateio_api_key', gateio_api_key)
  },
  setGateioApiSecret(state,gateio_api_secret ) {
    state.gateio_api_secret = gateio_api_secret
    localStorage.setItem('gateio_api_secret', gateio_api_secret)
  },
  setBitmaxApikey(state, bitmax_api_key) {
    state.bitmax_api_key = bitmax_api_key
    localStorage.setItem('bitmax_api_key', bitmax_api_key)
  },
  setBitmaxApiSecret(state,bitmax_api_secret ) {
    state.bitmax_api_secret = bitmax_api_secret
    localStorage.setItem('bitmax_api_secret', bitmax_api_secret)
  },
  setKucoinApikey(state, kucoin_api_key) {
    console.log(1)
    state.kucoin_api_key = kucoin_api_key
    localStorage.setItem('kucoin_api_key', kucoin_api_key)
  },
  setKucoinApiSecret(state,kucoin_api_secret ) {
    state.kucoin_api_secret = kucoin_api_secret
    localStorage.setItem('kucoin_api_secret', kucoin_api_secret)
  },
  setKucoinApiPassword(state,kucoin_api_password ) {
    state.kucoin_api_password = kucoin_api_password
    localStorage.setItem('kucoin_api_password', kucoin_api_password)
  },





  // crpyto data
  setCryptoAssets(state, assets) {
    localStorage.setItem('crypto_assets', JSON.stringify(state.assets))
  },
  syncCryptoCurrencies(state) {
    localStorage.setItem('vue-crypto-currencies-new', JSON.stringify(state.currencies))
  },

  updateCryptoMarketAssets(state, assets) {
    if (assets.length > 0) {
      let market = assets[0].market;
      for(let index=state.crypto_assets.length-1; index>=0; index--) {
        if (state.crypto_assets[index].market === market) {
          state.crypto_assets.splice(index, 1);
        }
      }
    }
    state.crypto_assets  = state.crypto_assets.concat((assets));
    localStorage.setItem('crypto_assets', JSON.stringify( state.crypto_assets));
  },
  updateCryptoMarketOrders(state, assets) {
    if (assets.length > 0) {
      let market = assets[0].market;
      for(let index=state.crypto_orders.length-1; index>=0; index--) {
        if (state.crypto_orders[index].market === market) {
          state.crypto_orders.splice(index, 1);
        }
      }
    }
    state.crypto_orders  = state.crypto_orders.concat((assets));
    localStorage.setItem('crypto_orders', JSON.stringify( state.crypto_orders));
  },
}

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions
})