/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_L5lcXQSv5",
    "aws_user_pools_web_client_id": "5e201gv6b1smlnml0tkdck8kfq",
    "oauth": {},
    API: {
        endpoints: [
            {
                name: "ManiacMansionAPI",
                endpoint: "https://api.maniacmansion.cloud"
            }
        ]
    }
};


export default awsmobile;
