<template>
  <router-view></router-view>
</template>

<script>
import { Auth, Hub } from 'aws-amplify'
export default {
  name: 'App'
}
Hub.listen('auth', (data) => {
    const { payload } = data
    // console.log('A new auth event has happened: ', data)
      if (payload.event === 'signIn') {
        // console.log('a user has signed in!'),
        window.location.href = '/';
      }
      if (payload.event === 'signOut') {
        // console.log('a user has signed out!')
        window.location.href = '/';
      }
  })

</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
