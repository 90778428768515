import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import '@aws-amplify/ui-vue'
import Amplify, { Auth, API } from 'aws-amplify'
import awsconfig from './aws-exports'
import VueRouter from 'vue-router'
import clickOutside from "./directives/click-ouside" 



import {
  applyPolyfills,
  defineCustomElements,
}  from '@aws-amplify/ui-components/loader'

Amplify.configure(awsconfig);
applyPolyfills().then(() => {
  defineCustomElements(window);
});




Vue.directive("click-outside", clickOutside);


Vue.use(VueRouter)
Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)
//Vue.http.options.emulateJSON = true 
// Vue.use(function(req, res, next) { res.header("Access-Control-Allow-Origin", "*"); res.header("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept"); next(); });
new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
